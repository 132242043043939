import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { InputLabel, Modal, Typography ,Box, IconButton, Paper, SvgIcon, CircularProgress, Rating} from '@mui/material';
import { QUICK_ACCESS_BG, QUICK_ACCESS_LOGO, DEFAULT_OLERN_TRANSPARENT_LOGO } from '../models/Constants';
import { isEmpty } from '../helpers/Functions';
import Divider from '../theme/partials/Divider';
import { Image } from 'react-bootstrap';
import StarIcon from '@mui/icons-material/Star';
import TextView from '../theme/partials/Typography';

const ReviewPage = ({ ...props }) => {
	const [isFullWidth, setIsFullWidth] = useState(false);
	const [paperStyle, setPaperStyle] = useState({})
	const [ratingValue, setRatingValue] = useState(null)
	const [hover, setHover] = useState(-1)

	const labels = [
		'',
		'1 Star',
		'2 Star',
		'3 Star',
		'4 Star',
		'5 Star',
	];

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	const query = useQuery();

	useEffect(() => {
		const fullWidthParam = query.get('full_width');
		if (fullWidthParam) {
			setIsFullWidth(fullWidthParam);
			setPaperStyle({ px: 3, pt: 3,pb: 5, borderRadius: 5})
		}
		else {
			setPaperStyle({ px: 2, pt: 2,pb: 3, borderRadius: 5})
		}
	}, [query]);

	const getLabelText = (value) => {
		return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
	}


	useEffect(() => {
		if(!isEmpty(ratingValue)) {
			if(ratingValue < 5){
				setTimeout(function() {
					window.location.href = "https://www.olern.com/details-of-feedback/";
				}, 1000)
			}
			else {
				setTimeout(function() {
					window.location.href = "https://www.olern.com/gift-of-feedback/";
				}, 1000)
			}
		}
	}, [ratingValue])
	

	return (<>
		<ToastContainer />
		<div className={`${(isFullWidth) ? "qa-wide-container" : "mobile-container"} p-3`}
			style={{
				backgroundImage: "url(" + QUICK_ACCESS_BG + ")", 
				backgroundSize: 'cover', 
				backgroundPosition: 'center', 
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='px-1 w-100'>
				<center>
					<Image
						className={`d-flex m-2 mb-5`}
						style={{
							backgroundColor: 'transparent',
							width: "20vh",
							objectFit: 'contain'
						}}
						src={DEFAULT_OLERN_TRANSPARENT_LOGO}
						alt="Olern Logo"
					/>
				</center>
				<Paper 
					elevation={3}
					sx={paperStyle}
					className='my-5'
				>
					{
						(!isFullWidth) && (
							<center>
								<Image
									className="d-flex m-2"
									style={{
										backgroundColor: 'transparent',
										width: "20vw",
										objectFit: 'contain'
									}}
									src={QUICK_ACCESS_LOGO}
									alt="Olern Logo"
								/>
							</center>
						)
					}
					<center>
						<TextView className="font-weight-medium font-size-xl m-1 mb-2 color-quinary">Based on your recent experience with us, how would you rate Olern?</TextView>
						<TextView className="font-weight-medium font-size-medium m-1">Your feedback means a lot to us.</TextView>
						<div
							className="mb-2"
						>
							<Rating
								sx={{
								fontSize: {
									xs: '3rem',
									sm: '4rem',
									md: '5rem',
									lg: '6rem',
								},
								}}
								className='responsive-rating'
								name="hover-feedback"
								value={ratingValue}
								getLabelText={getLabelText}
								onChange={(event, newValue) => {
									setRatingValue(newValue);
								}}
								onChangeActive={(event, newHover) => {
									setHover(newHover);
								}}
								emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
							/>
							<Box 
								sx={{ ml: 2 }}
							>
								{hover !== -1 ? labels[hover !== -1 ? hover : ratingValue] : 
								isEmpty(ratingValue) ? "Please select a rating." : labels[ratingValue]}
							</Box>
						</div>
					</center>
				</Paper>
				{
					(!isFullWidth) ? (
						<div className='d-flex flex-column mt-4 w-100 overflow-x-auto'>
							<div className='mt-4 mb-3 font-weight-heavy'>
								<center>
									Get FREE <a href={process.env.REACT_APP_WP_URL} target='_blank'>Trainings for SMEs!</a>
								</center>
							</div>
						</div>
					) : (<div><Divider className='my-3'/></div>)
				}
			</div>
		</div>
	</>);
};

export default ReviewPage;