import {Buffer} from 'buffer';
import moment from 'moment-timezone';
import displayToast from '../theme/partials/DisplayToast';

export function objectToUrlQuery(filter) {
	let params = "";

	if(filter !== null) {
		// eslint-disable-next-line
		Object.keys(filter).map(function(key, index) {
			if(index === 0) {
				params = `${key}=${filter[key]}`;
			}
			else {
				params = params + `&${key}=${filter[key]}`;
			}
		});
	}

	return params;
}

export function decryptString(string) {
	var CryptoJS = require('crypto-js');

	var key = process.env.REACT_APP_API_KEY;
	let encrypted = Buffer.from(string, 'base64');
	encrypted = JSON.parse(encrypted);
	const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
	const value = encrypted.value;
	key = CryptoJS.enc.Base64.parse(key);
	
	var decrypted = CryptoJS.AES.decrypt(value, key, {
		iv: iv
	});

	return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

export function timeFromString(timeString, timeFormat = "HH:mm:ss", formatTo = "hh:mm A") {
	return moment.tz(timeString, timeFormat, "Asia/Manila").format(formatTo); // TODO: must have settings for timezone
}

export function mergeArrayOfObjects(arr1, arr2) {
	return arr1.concat(arr2).reduce((acc, object) => {
		Object.entries(object).forEach(([key, value]) => {
			if(!acc.hasOwnProperty(key)) {
				acc[key] = value;
			}
		});

		return acc;
	}, {});
}

export function isEmpty(obj) {
	if (obj === null || obj === undefined) {
		return true;
	}

	if(typeof obj === "undefined") {
		return true;
	}

	if(obj instanceof File) {
		if(obj.size == 0) {
			return true;
		}
		else {
			return false;
		}
	}

	if(typeof obj === "object" && Object.keys(obj).length === 0) {
		return true;
	}

	if(typeof obj === "string" && obj.length === 0) {
		return true;
	}

	if(typeof obj === "string" && obj === "null") {
		return true;
	}

	if(typeof obj === "string" && obj === "undefined") {
		return true;
	}

	return false
}

export function isVideoFile(link) {
	const fileExtensions = [
		".mp4",
		".mov",
		".avi",
		".wmv",
		".mkv",
		".flv",
		".webm"
	];

	return compareLink(fileExtensions, link);
}

export function isAudioFile(link) {
	const fileExtensions = [
		".mp3",
		".m4a",
		".flac",
		".mp4",
		".wav",
		".wma",
		".aac"
	];

	return compareLink(fileExtensions, link);
}

function compareLink(fileExtensions, link) {
	if(link) {
		for(const fileExtension of fileExtensions) {
			if(link.includes(fileExtension)) {
				return true;
			}
		}
	}

	return false;
}

export function formatDate(dateString, format="MMM DD, YYYY") {
	const date = new Date(dateString);
	return moment(date).format(format);
}

export function toNumerical(wordString){
	const numberOnly = wordString.replace(/\D/g, '');
	const noLeadingZeros = numberOnly.replace(/^0+/, '');
	const count = noLeadingZeros === '' ? '0' : noLeadingZeros;

	return count
}

export function makeCapitalize(inputString) {
	return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export const paramFinder = ( prompt , paramValues, ignoreError = false) => {
	const pattern = /\{\{(industry|team|role|product|product_description|language|target|rank)\}\}/g;
	let error = false

	let replacedString = prompt.replace(pattern, (match, word) => {
		if (match == "{{rank}}") {
			return paramValues["identification_name"] || match;
		} 
		else if(isEmpty(paramValues[word])) {
			if(!ignoreError){
				displayToast("error", `You have a parameter ${match} on your prompt but did not give a definition, Please fill up the ${match} textbox.`);
				error = true;
			}

			return match
		}

		return paramValues[word] || match;
	});

	if(error) {
		return false
	}

	return replacedString
};

export function transformArrayToObject(inputArray, keyWord, valueWord) {
	const resultObject = {};
  
	inputArray.forEach(item => {
		resultObject[item[keyWord].toLowerCase()] = [item[valueWord]];
	});

	return resultObject;
}

export function trimAIResponseToArray(response) {
	const startIndex = response.indexOf('[');
	const endIndex = response.lastIndexOf(']');

	if (startIndex !== -1 && endIndex !== -1) {
		const trimmedString = response.substring(startIndex , endIndex + 1).trim();
	
		return trimmedString;
	} 
	else {
		return null 
	}
}

export function transformBoldElement (wordString, format) {
	let output = "";

	if(format == "element") {
		output = wordString.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
	}
	else {
		output = wordString.replace(/<b>(.*?)<\/b>/g, '**$1**');
	}
	
	return output
}

export function isPageQuickAccess(location) {
	const firstPathSegment = location.pathname.split('/')[1];
	const isQuickAccess = firstPathSegment === 'me';

	return isQuickAccess
}

export function getRandomColor(brightness) {
	function randomChannel(brightness) {
		var r = 255 - brightness;
		var n = 0 | (Math.random() * r + brightness);
		var s = n.toString(16);
		return (s.length == 1) ? '0' + s : s;
	}
	return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}

export function isValidEmail(value) {
	return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
}

export function findNumbersOnArray(arr, nums) {
	//todo: return always true when on dev to access all features
	if(Array.isArray(arr)){
		return nums.every(num => arr.includes(num));
	}
	else {
		return false
	}
}

export function deadlineConstantsToDate(dayAdd, currentDate = new Date()) {
	return new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		currentDate.getDate() + dayAdd,
	);
}

export function isObject(variable) {
	return variable !== null && typeof variable === 'object' && !Array.isArray(variable);
}

export function findFirstNullProgressReportIndex(data) {
	if(isEmpty(data[0]?.lessons[0].progress_report)){
		return { parentIndex: "starting", sort: 0, next_unopened_lesson: 0, completed_lesson: 0 }
	}
	else {
		const progress = data.find((parent, parentIndex) => {
			const allLessonsHaveProgress = parent.lessons.every(lesson => lesson.progress_report !== null);
		
			if (!allLessonsHaveProgress) {
				return true; // This will cause find to return the current parent
			}
		
			return false; // continue to next parent
		});
		
		if (progress) {
			const parentIndex = data.indexOf(progress);

			let lessonIndex = progress.lessons.findIndex(lesson => lesson.progress_report === null);
			let completedLesson = 0;

			if(lessonIndex > 0) {
				const lastLessonCompleted = data[parentIndex].lessons[lessonIndex - 1].progress_report.is_completed

				if(lastLessonCompleted) {
					completedLesson = lessonIndex;
					lessonIndex = lessonIndex + 1;
				}
			}

			if(lessonIndex == 0) {
				const lastModuleIndex = parentIndex - 1;

				if(lastModuleIndex >= 0){
					let lastModuleLastLesson = data[lastModuleIndex].lessons.length - 1
					let lastModuleLastLessonData = data[lastModuleIndex].lessons[lastModuleLastLesson]

					if(lastModuleLastLessonData.progress_report.is_completed) {
						completedLesson = parentIndex
					}
				}
			}

			const result = { parentIndex: parentIndex, sort: progress.sort, next_unopened_lesson: lessonIndex, completed_lesson: completedLesson};

			return result;
		}
		else {
			const result = { parentIndex: "finished", sort: 0, next_unopened_lesson: 0, completed_lesson: 0 };

			return result;
		}
	}
}

export const base64ToBlob = (dataURL) => {
	const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const byteString = atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mime });
};

export function ensureArray(variable) {
	if (Array.isArray(variable)) {
		return variable;
	}
	else if (typeof variable === 'string') {
		if (variable.includes(',')) {
			return variable.split(',').map(item => item.trim());
		} 
		else {
			return [variable];
		}
	}
	else {
		return [];
	}
}