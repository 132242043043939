export const DEFAULT_AUTH_HEADER = {
	headers: {
		"Authorization": "Bearer " + process.env.REACT_APP_DEFAULT_API_KEY
	}
};

//todo: to be changed on role revisions 
export const ROLE_ADMIN = 1;
export const ROLE_STUDENT = 2;
export const ROLE_MENTOR = 3;
export const ROLE_SUPER_ADMIN = 4;
export const ROLE_EMPLOYEE = 5;
export const ROLE_OWNER = 6;

//todo: to be changed on role revisions 
export const ROLES = [
	{ value: ROLE_ADMIN, label: "Admin" },
	{ value: ROLE_STUDENT, label: "Student" },
	{ value: ROLE_MENTOR, label: "Mentor" },
	{ value: ROLE_SUPER_ADMIN, label: "Super Admin" },
	{ value: ROLE_EMPLOYEE, label: "Employee" },
	{ value: ROLE_OWNER, label: "Owner" }
];

export const DIR_DEFAULT_RESOURCE= "/resource_uploads/"
export const DIR_LIBRARY_IMAGE = "/library_uploads/images/";
export const DIR_COMPANY_IMAGE = "/company_uploads/images/";
export const DIR_LESSON_AUDIO = "/lesson_attachment_uploads/audios/";
export const DIR_LESSON_UPLOAD = "/lesson_attachment_uploads/documents/";
export const DIR_USER_PROFILE = "/user_profile_images/";
export const DIR_INSTRUCTOR_PROFILE = "/instructor_profile_images/";
export const DIR_VID_SUBTITLES = "/lesson_attachment_uploads/video_subtitles/";
export const DIR_QUOTES_TEMPLATES = "/resource_uploads/quotes_templates/"

export const DEFAULT_LIBRARY_IMAGE = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "defaultgirl.png";
export const DEFAULT_OLERN_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "olern-logo.png";
export const DEFAULT_OLERN_TRANSPARENT_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "olern-logo-transparent.png";
export const DEFAULT_WARNING_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "warning-logo.png";
export const DEFAULT_APPROVE_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "approve-logo.png";
export const DEFAULT_USER_IMAGE = process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + "default_user_image.png";
export const DEFAULT_RESTRICT_IMAGE = process.env.REACT_APP_S3_BUCKET + "/restrictImage.png";

export const QUICK_ACCESS_BG = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "QuickAccessBackground.jpg";
export const QUICK_ACCESS_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "quick-access-logo.png";
export const INTLIGUIDE_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "quick-access-logo-2.png";

export const QUOTES_TEMPLATE_URL = process.env.REACT_APP_S3_BUCKET + DIR_QUOTES_TEMPLATES;

export const IMAGE_ELEMENT = 1;
export const AUDIO_ELEMENT = 2;
export const DOCUMENT_ELEMENT = 3;
export const VIDEO_ELEMENT = 4;
export const TEXT_ELEMENT = 5;
export const REFLECTION_ELEMENT = 6;
export const SIGNATURE_ELEMENT = 7;
export const SURVEY_ELEMENT = 8;
export const LINK_ELEMENT = 9;
export const DOWNLOAD_ELEMENT = 10;
export const QUIZ_ELEMENT = 11;
export const ACTIONS_STEP_ELEMENT = 12;
export const ACTION_SUMMARY_ELEMENT = 13;
export const WORD_CLOUD_ELEMENT = 14;
export const QUOTES_ELEMENT = 15;
export const RATING_ELEMENT = 16;

//AI Generated Content
export const AI_QUIZ_ELEMENT = 101;

export const DEADLINE_TOMORROW = 1;
export const DEADLINE_TWO_WEEKS = 2;
export const DEADLINE_FOUR_WEEKS = 3;
export const DEADLINE_CUSTOM = 4;
export const DEADLINE_ONE_WEEK = 5;
export const DEADLINE_ONE_MONTH = 6;

export const WP_PRODUCT_URL = process.env.REACT_APP_WP_URL + process.env.REACT_APP_WP_PRODUCT_PATH;

export const SURVEY_SINGLE = 1;
export const SURVEY_MULTI = 2;
export const SURVEY_SORT = 3;


export const MASTER_PROMPT_HEADER = `\n and return it in this JSON format : `;
export const MASTER_PROMPT_FOOTER = `please do not provide introduction,
please do not describe your answer,
do not reply in any other format just in JSON,
do not include other text outside the array,
the whole response must be in a single array,
response must start with '[' and ends with ']'`;

export const AI_QUIZ_PROMPT_RETURN = MASTER_PROMPT_HEADER + 
`"[
	{
		"key": "",// a unique 21 character string
		"question" : "",
		"quiz_context" : {
			"question_type":"", // can be bool=(true or false)/choice=(single correct)/multi=(multiple correct)
			"points":2, // can be bool=2/choice=3/multi=5
			"explain": {
				"status": true, // always true
				"value":"" // explain why the choice is correct
			},
			"show_correct":true // always true
		},
		"quiz_choices" : [
			{"id":0,"description":"Choice A","context":"correct"},
			{"id":0,"description":"Choice B","context":"incorrect"}
			{"id":0,"description":"Choice C","context":"incorrect"}//id is always 0
		]//"multi" question type must have multiple correct in context, "bool" will always have only True or False as choices
	},
	// add the other object here
]"` +
MASTER_PROMPT_FOOTER; 

export const AI_REFLECTION_PROMPT_RETURN = MASTER_PROMPT_HEADER + 
`"[
	{
		"Id" : 0, 
		"answer" : "(Your Answer)"
	},
	// add the other object here
]"` +
MASTER_PROMPT_FOOTER; 

export const AI_TEXT_PROMPT_RETURN = `please do not describe your answer,
Return your response in html`


//Sales Process Constants
export const SP_CREATE_TAG = "Olern-Created-Account";
export const SP_COMPLETE_TAG = "Olern-Completed-Details";

export const HTTPS_LINK_PATTERN = /^https:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^/?#]*)?\/?$/;
export const SP_FROM_QUICK_ACCESS = "new signup - qa";

export const PAYMENT_TYPES = [
	"All",
	"Apple Pay",
	"Google Pay",
	"Credits",
	"Credit Card",
	"Gcash",
	"Paymaya",
	"Paypal",
	"Bank Transfer",
	"Others",
];

export const PRODUCT_TYPES = [
	"All",
	"Course",
	"Credit",
]

export const LIBRARY_TYPE_TRAINING = 1;
export const LIBRARY_TYPE_MANUAL = 2;
export const LIBRARY_TYPE_POLICY = 3;

export const SEARCH_TIMER_DELAY = 800;
export const SEARCH_RESULT_COUNT = 8;

export const DUE_DATE_SHORTCUTS = [
	{
		id: DEADLINE_TOMORROW,
		label: "Tommorow",
		day_add: 1,
	},
	{
		id: DEADLINE_ONE_WEEK,
		label: "In 1 Week",
		day_add: 7,
	},
	{
		id: DEADLINE_ONE_MONTH,
		label: "In 1 Month",
		day_add: 30,
	},
];

export const PERMISSIONS_VIEW_CHANNEL = 1
export const PERMISSIONS_EDIT_CHANNEL = 2	
export const PERMISSIONS_VIEW_CATEGORY = 3
export const PERMISSIONS_EDIT_CATEGORY = 4	
export const PERMISSIONS_VIEW_USERS = 5
export const PERMISSIONS_EDIT_USERS = 6
export const PERMISSIONS_VIEW_TEAMS = 7
export const PERMISSIONS_EDIT_TEAMS = 8
export const PERMISSIONS_VIEW_POSITIONS = 9	
export const PERMISSIONS_EDIT_POSITIONS = 10
export const PERMISSIONS_VIEW_CREDITS = 11
export const PERMISSIONS_EDIT_CREDITS = 12
export const PERMISSIONS_CREATE_LIBRARY = 13
export const PERMISSIONS_EDIT_LIB_OVERVIEW = 14
export const PERMISSIONS_EDIT_LIB_OUTLINE = 15
export const PERMISSIONS_EDIT_LIB_SETTINGS = 16
export const PERMISSIONS_PUBLISH_LIB = 17
export const PERMISSIONS_VIEW_ENROLL = 18
export const PERMISSIONS_ENROLL_STUDENTS = 19
export const PERMISSIONS_EDIT_STUDENT_ACCESS = 20
export const PERMISSIONS_ADD_LIB_MENTOR = 21
export const PERMISSIONS_ENROLL_COMPANY = 22
export const PERMISSIONS_ENROLL_TEAMS = 23
export const PERMISSIONS_ENROLL_POSITIONS = 24
export const PERMISSIONS_IMPORT_EXPORT_ENROLLEES = 25
export const PERMISSIONS_VIEW_ROLES = 26
export const PERMISSIONS_EDIT_ROLES = 27

export const SUPER_PERMISSIONS = [
	PERMISSIONS_VIEW_CHANNEL,
	PERMISSIONS_EDIT_CHANNEL,
	PERMISSIONS_VIEW_CATEGORY,
	PERMISSIONS_EDIT_CATEGORY,
	PERMISSIONS_CREATE_LIBRARY,
	PERMISSIONS_EDIT_LIB_OVERVIEW,
	PERMISSIONS_EDIT_LIB_OUTLINE,
	PERMISSIONS_EDIT_LIB_SETTINGS,
	PERMISSIONS_PUBLISH_LIB,
	PERMISSIONS_VIEW_ENROLL,
	PERMISSIONS_ENROLL_STUDENTS,
	PERMISSIONS_EDIT_STUDENT_ACCESS,
	PERMISSIONS_ADD_LIB_MENTOR,
	PERMISSIONS_ENROLL_COMPANY,
	PERMISSIONS_ENROLL_TEAMS,
	PERMISSIONS_ENROLL_POSITIONS,
	PERMISSIONS_IMPORT_EXPORT_ENROLLEES,
	PERMISSIONS_VIEW_ROLES,
	PERMISSIONS_EDIT_ROLES
]

export const MENTOR_DEFAULT_IMAGE = QUOTES_TEMPLATE_URL + "default_mentor_image.png"

export const QUOTES_TEMPLATES = [
	{
		"profile_image" : {"type": "image", "url" : MENTOR_DEFAULT_IMAGE, "pos_x": 130, "pos_y": 300,"pos_z": "below", "height": 140, "width": 140},
		"profile_name" : {"type": "text", "text" : "","pos_x": 110, "pos_y": 400,"width": 300, "size": 24, "align": "left", "color": "black", "family": "Open Sans"},
		"course_name": {"type": "text", "text" : "", "pos_x": 125, "pos_y": 430, "width": 300, "size": 16, "align": "left", "color": "black", "family": "Open Sans"},
		"quote": { "type": "text", "text" : "", "pos_x": 90, "pos_y": 80,"width": 300, "family": "Open Sans","size": 48, "color": "--theme-primary", "align": "left"},
		"images" : { "type": "image", "base": QUOTES_TEMPLATE_URL + "template_1.png", "preview" : QUOTES_TEMPLATE_URL + "template_1_preview.png"},
		"name" : "template_1"
	},
	{
		"profile_image" : {"type": "image", "url" : MENTOR_DEFAULT_IMAGE, "pos_x": 220, "pos_y": 180, "pos_z": "above", "height": 200, "width": 200},
		"profile_name" : {"type": "text", "text" : "", "pos_x": 50, "pos_y": 270,"width": 300, "size": 20, "align": "left", "color": "black", "family": "Open Sans"},
		"course_name": {"type": "text", "text" : "", "pos_x": 50, "pos_y": 295, "width": 200, "size": 14, "align": "left", "color": "black", "family": "Open Sans"},
		"quote": { "type": "text", "text" : "", "pos_x": 50, "pos_y": 100,"width": 140, "family": "Open Sans", "size": 16, "color": "--theme-black", "align": "left"},
		"images" : { "type": "image", "base": QUOTES_TEMPLATE_URL + "template_2.png", "preview" : QUOTES_TEMPLATE_URL + "template_2_preview.png"},
		"name" : "template_2"
	},
	{
		"profile_image" : {"type": "image", "url" : MENTOR_DEFAULT_IMAGE, "pos_x": 250, "pos_y": 350, "pos_z": "below", "height": 160, "width": 160},
		"profile_name" : {"type": "text", "text" : "", "pos_x": 50, "pos_y": 370,"width": 300, "size": 26, "align": "left", "color": "white", "family": "Open Sans"},
		"course_name": { "type": "text", "text" : "", "pos_x": 50, "pos_y": 400, "width": 300, "size": 14, "align": "left", "color": "white", "family": "Open Sans"},
		"quote": { "type": "text", "text" : "", "pos_x": 50, "pos_y": 100,"width": 335, "family": "Open Sans","size": 26, "color": "--theme-octonary", "align": "left"},
		"images" : { "type": "image", "base": QUOTES_TEMPLATE_URL + "template_3.png", "preview" : QUOTES_TEMPLATE_URL + "template_3_preview.png"},
		"name" : "template_3"
	},
]

export const DEFAULT_EMAIL_FOOTER = `<p>DID YOU KNOW... that our Olern App can personalize examples for you and your team?</p>
<br>
<p>Unlike other online learning platforms, we use Artificial Intelligence (AI) to make the learning experience tailor - fitted to your role and industry so it’s straight to the point and much easier to digest. It truly is a learning adventure...</p>
<br>
<p>Just imagine how much more personalized learning you and your team can get with the Olern App.</p>
<br>
<p>So download it now here: <a href="https://www.olern.com/download">https://www.olern.com/download</a></p>`