import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

const BaseTextField = ({display = 'block', ...props}) => {
	return (
		<div className={`w-100 mb-3 d-${display} ${props.customclasses}`}>
			<div className={`d-flex justify-content-between`}>
				{props.title && (
					<InputLabel
						className={`
							${(props.titleclass) ? props.titleclass :
							"font-size-medium color-septenary"}
							mb-2`
						}
					>
						{props.title}
					</InputLabel>
				)}

				{props?.extralink && (
					props.extralink
				)}
			</div>
			
			{
				<TextField
					className="w-100"
					inputProps={{
						className: {input: "content-textfield"}
					}}
					{...props}
				/>
			}

			
		</div>
	)
}

export default BaseTextField;